import { TFunction } from "i18next";
import { IColumnProps } from "../../../../components/kendo/DataGridApi/interfaces/IColumnProps";
import {
	getChileZoneOptions,
	getExportationStatusOptions,
	getFollowUpChlHighlightOptions,
} from "../../../../utils/SelectOptions";
import { getDateTimeCell } from "../../../../utils/GenericGridUtils";

export function getFollowUpChlGridColumns(
	t: TFunction<"followUpChl"[], undefined>
): Array<IColumnProps> {
	return [
		{
			field: "highlight",
			title: " ",
			alwaysVisible: true,
			width: 28,
			sortable: true,
			locked: true,
			customCell: {
				type: "optionCell",
				value: "highlight",
				options: getFollowUpChlHighlightOptions(t),
				badge: "circle",
			},
		},
		{
			field: "factoryType",
			title: t("gridColumns.factoryType"),
		},
		{
			field: "zone",
			title: t("gridColumns.zone"),
			customCell: {
				type: "optionCell",
				value: "zone",
				options: getChileZoneOptions(t),
			},
		},
		{
			field: "aoomNumber",
			title: t("gridColumns.aOOMNumber"),
			width: 130,
			alwaysVisible: true,
			customCell: {
				type: "linkCell",
				to: "./",
				toParams: "factoryType;id",
				value: "aoomNumber",
			},
		},
		{
			field: "chassi",
			title: t("gridColumns.chassi"),
			width: 60,
		},
		{
			field: "commercialModel",
			title: t("gridColumns.commercialModel"),
		},
		{
			field: "package",
			title: t("gridColumns.package"),
		},
		{
			field: "cabin",
			title: t("gridColumns.cabin"),
		},
		{
			field: "color",
			title: t("gridColumns.color"),
		},
		{
			field: "customerOM",
			title: t("gridColumns.customerOM"),
		},
		{
			field: "customer",
			title: t("gridColumns.customer"),
		},
		{
			field: "salesman",
			title: t("gridColumns.salesman"),
		},
		{
			field: "stockStatus",
			title: t("gridColumns.stockStatus"),
			customCell: {
				type: "optionCell",
				value: "stockStatus",
				options: getExportationStatusOptions(t),
			},
		},
		{
			field: "asWeek",
			title: t("gridColumns.asWeek"),
		},
		{
			...getDateTimeCell("asWeekDate", t("gridColumns.asWeekDate")),
		},
		{
			...getDateTimeCell("greenOk", t("gridColumns.greenOk")),
		},
		{
			...getDateTimeCell("cdd", t("gridColumns.cdd")),
		},
		{
			...getDateTimeCell("lastChangeDate", t("gridColumns.lcd")),
		},
		{
			...getDateTimeCell(
				"invoiceDateProposal",
				t("gridColumns.invoiceDateProposal")
			),
		},
		{
			...getDateTimeCell(
				"deliveryDateProposal",
				t("gridColumns.deliveryDateProposal")
			),
		},
	];
}
